







































































































































import { Component, Vue, Prop, Computed } from "vue-property-decorator";
import ExportData from "@/components/export-data/index.vue";
import DatePicker from "@/components/date-picker.vue";
import return_line from "./components/return_line.vue";
import { apiInformationRefund } from "@/api/data_center";
import { timeFormat } from "@/utils/util";

@Component({
    components: {
        DatePicker,
        return_line,
    },
})
export default class overview extends Vue {
    // S Data
    form: any = {
        start_time: "",
        end_time: "",
        type: 1,
    };
    buttonGroup = [
        {
            name: "日",
            type: 1,
        },
        {
            name: "周",
            type: 2,
        },
        {
            name: "月",
            type: 3,
        },
        {
            name: "年",
            type: 4,
        },
    ];
    loading = false;
    after_chart = {};
    after_sale = {};
    refund_reason = [];
    refund_goods = [];
    refund_resource = [];
    // E Data
    tableHeight = 0;
    // S Methods

    changeDate(e) {
        this.form.start_time = e[0]
        this.form.end_time = e[1]
        this.getData(this.form.type);
    }

    getData(type = 1) {
        this.loading = true;
        this.form.type = type;
        apiInformationRefund(this.form)
            .then((res) => {
                this.loading = false;
                this.after_chart = res.after_chart;
                this.after_sale = res.after_sale;
                this.refund_reason = res.refund_reason;
                this.refund_goods = res.refund_goods;
                this.refund_resource = res.refund_resource;
                const length =
                    res.refund_reason > res.refund_goods
                        ? res.refund_reason.length
                        : res.refund_goods.length;
                this.tableHeight = (length + 1) * 48;
            })
            .catch(() => {
                this.loading = false;
            });
    }
    // E Methods

    // S  life cycle
    created() {
        const end = new Date();
        const start = new Date();
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
        this.form.start_time = timeFormat(start, "yyyy-mm-dd");
        this.form.end_time = timeFormat(end, "yyyy-mm-dd");
        this.getData(1);
    }

    // E life cycle
}
